<template>
    <Transition
        enter-from-class="opacity-0 translate-x-10"
        leave-to-class="opacity-0 translate-x-10"
        enter-active-class="transition duration-300"
        leave-active-class="transition duration-300"
    >
        <div
            v-if="show && !disabled"
            class="pr-10 p-4 text-sm rounded-lg shadow-xl flex gap-2.5 text-white shrink-0 pointer-events-auto relative select-none"
            :class="styles[type].box"
        >
            <Icon :name="styles[type].icon" :weight="400" />

            <div class="grid gap-1">
                <div class="font-semibold">{{ label }}</div>
                <div class="">{{ text }}</div>
            </div>

            <button
                class="size-7 rounded-full hover:bg-white/20 flex items-center justify-center absolute top-2 right-2 duration-150"
            >
                <Icon name="close" :size="18" @click="notification.remove(id)" />
            </button>
        </div>
    </Transition>
</template>

<script setup>
const show = ref(false);
const notification = useNotification();

const styles = {
    success: {
        icon: 'check_circle',
        box: 'bg-green-600/80',
    },

    danger: {
        icon: 'error',
        box: 'bg-red-600/80',
    },

    warning: {
        icon: 'report',
        box: 'bg-amber-600/80',
    },
};

defineProps({
    id: {
        type: Number,
        default: null,
    },

    type: {
        type: String,
        default: '',
    },

    label: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    disabled: {
        type: Boolean,
        default: true,
    },
});

onMounted(() => {
    show.value = true;
});
</script>
