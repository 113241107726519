export default defineNuxtPlugin(async () => {
    if (import.meta.server) {
        const { authCookie } = useRuntimeConfig();
        const { name } = authCookie;
        const { value: cookie } = useCookie(name);

        if (cookie) {
            try {
                const basicAccount = await $fetch('/api/account/check', {
                    headers: useRequestHeaders(['cookie']),
                    method: 'POST',
                });

                const { account } = useStore();
                account.value = basicAccount;
            } catch (error) {
                console.log(error.statusMessage);
            }
        }
    }
});
